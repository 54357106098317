<template>
  <div class="fixed w-full z-30 transition duration-300 ease-in-out bg-light px-4 xl:px-0">
    <div class="max-w-xs sm:max-w-lg md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 3xl:max-w-[1440px] mx-auto">
      <div class="flex items-center justify-between h-16">
        <div class="shrink-0 flex flex-row items-center justify-center">
          <router-link 
            :to="{name: 'Home'}">
            <img :src="require('../../assets/logo.svg')" width="56px" height="56px">
          </router-link>
        </div>

        <div class="shrink-0 flex flex-row items-center justify-center">
          <p class="text-base font-medium">
            Go to
          </p>
          <div class="flex mr-4">
            <a
              :href="parentURL"
              target="_self"
              class="text-base font-medium text-primary underline ml-2">
              Xdemia
            </a>
          </div>
          <div
            class="relative inline-block text-left"
              @mouseover="hover=true"
              @mouseleave="hover=false">
            <button
              v-if="isLogin"
              type="button"
              class="shrink-0 flex flex-row items-center justify-center bg-primary-light hover:bg-primary--200 active:bg-primary--300 rounded-full w-[40px] h-[40px]"
              @click="show = true">
              <xdem-icon
                icon="bg-xdem-arrow-bottom"
                size="24"/>
            </button>
            <div
              v-show="show"
              class="absolute right-0 z-10 mt-2 w-44 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="m-2 hover:bg-primary-light rounded-lg">
                <button
                  class="block w-full px-4 py-2 text-left text-sm text-primary-dark"
                  @click="logout">
                  Log out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { useAuthStore } from '@/store';

export default {
  name: 'HeaderMenu',
  
  components: {
  },
  data() {
    return {
      show: false,
      hover: false,
    };
  },
  watch: {
    hover: _.debounce(function debounced() {
      if (!this.hover) {
        this.show = false
      }
    }, 500),
  },
  computed: {
    parentURL() {
      return process.env.VUE_APP_PARENT_URL;
    },
    isLogin() {
      return localStorage.getItem('accessToken');
    },
  },
  methods: {
    async logout() {
      await useAuthStore().logout();
      this.$router.push(`/login`);
    },
  },
}
</script>
