<template>
  <div
    v-show="isVisible"
    class="relative z-50"
    @click.self="close">
    <div
      class="fixed inset-0 bg-gray bg-opacity-75 transition-opacity"
      aria-hidden="true"
      @click="close">
    </div>
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
          <div class="flex justify-between flex-row px-4 py-3 border-b border-gray">
            <p class="text-base font-medium fw-400">
              {{ modalAttributes.header }}
            </p>
            <xdem-icon
              icon="bg-xdem-close"
              size="24"
              @click="close"
            />
          </div>
          <div class="flex flex-col items-center px-8 py-8 sm:py-12 mt-3 text-center sm:mt-0 sm:text-left">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">
              {{ modalAttributes.title }}
            </h3>
            <p class="text-sm font-normal text-center mt-2">
              {{ modalAttributes.message }}
            </p>
          </div>
          <div
            v-if="modalAttributes.button.length"
            class="bg-gray-50 px-3 py-3 sm:flex sm:flex-row-reverse border-t border-gray">
            <button
              v-for="(button, idx) in modalAttributes.button"
              :key="`modal-button-${idx}`"
              type="button"
              class="inline-flex w-full justify-center rounded-md px-3 py-2 text-base font-semibold shadow-sm sm:ml-3 sm:w-auto sm:min-w-[140px]"
              :class="`${button.background} ${idx === modalAttributes.button.length ? 'mt-3' : ''}`"
              @click="button.function">
              {{ button.text }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PopupModal',
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    modalAttributes: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      required: false
    },
  },
  methods: {
  }
};
</script>
