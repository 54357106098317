<template>
  <header-menu />
  <div class="max-w-xs sm:max-w-lg md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 3xl:max-w-[1440px] mx-auto bg-gray mt-24 mb-8 w-full">
    <slot/>
  </div>
</template>

<script>
import HeaderMenu from '@/components/Layout/HeaderMenu';

export default {
  name: 'LayoutMain',
  components: { HeaderMenu }
}
</script>
