<template>
  <div v-if="editor">
    <bubble-menu
      class="bubble-menu flex bg-slate-50 items-center gap-2 bg-light p-2 w-fit"
      :tippy-options="{ duration: 100 }"
      :editor="editor"
    >
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        @click="editor.chain().focus().toggleBold().run()" 
        :class="{ 'bg-slate-400': editor.isActive('bold') }">
        <xdem-icon
          icon="bg-xdem-bold"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        @click="editor.chain().focus().toggleItalic().run()" 
        :class="{ 'bg-slate-400': editor.isActive('italic') }">
        <xdem-icon
          icon="bg-xdem-italic"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        @click="editor.chain().focus().toggleStrike().run()" 
        :class="{ 'bg-slate-400': editor.isActive('strike') }">
        <xdem-icon
          icon="bg-xdem-strike-through"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        @click="editor.chain().focus().toggleUnderline().run()" 
        :class="{ 'bg-slate-400': editor.isActive('underline') }">
        <xdem-icon
          icon="bg-xdem-underline"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        @click="editor.chain().focus().toggleCode().run()" 
        :class="{ 'bg-slate-400': editor.isActive('code') }">
        <xdem-icon
          icon="bg-xdem-inline-code"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        @click="editor.commands.focus().setParagraph()" 
        :class="{ 'bg-slate-400': editor.isActive('paragraph') }">
        <xdem-icon
          icon="bg-xdem-paragraph"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        :class="{ 'bg-slate-400': editor.isActive('heading', { level: 1 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()">
        <xdem-icon
          icon="bg-xdem-heading-1"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        :class="{ 'bg-slate-400': editor.isActive('heading', { level: 2 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
        <xdem-icon
          icon="bg-xdem-heading-2"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        :class="{ 'bg-slate-400': editor.isActive('heading', { level: 3 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
        <xdem-icon
          icon="bg-xdem-heading-3"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        :class="{ 'bg-slate-400': editor.isActive('bulletList') }"
        @click="editor.chain().focus().toggleBulletList().run()">
        <xdem-icon
          icon="bg-xdem-bullet-list"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        :class="{ 'bg-slate-400': editor.isActive('orderedList') }"
        @click="editor.chain().focus().toggleOrderedList().run()">
        <xdem-icon
          icon="bg-xdem-numbered-list"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        :class="{ 'bg-slate-400': editor.isActive('blockquote') }"
        @click="editor.chain().focus().toggleBlockquote().run()">
        <xdem-icon
          icon="bg-xdem-quote"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        :class="{ 'bg-slate-400': editor.isActive('codeBlock') }"
        @click="editor.chain().focus().toggleCodeBlock().run()">
        <xdem-icon
          icon="bg-xdem-block-code"
          size="28"/>
      </div>
    </bubble-menu>

    <floating-menu
      class="floating-menu flex bg-slate-50 items-center gap-2 bg-light p-2 w-fit"
      :tippy-options="{ duration: 100 }"
      :editor="editor"
    >
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        @click="editor.chain().focus().toggleBold().run()" 
        :class="{ 'bg-slate-400': editor.isActive('bold') }">
        <xdem-icon
          icon="bg-xdem-bold"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        @click="editor.chain().focus().toggleItalic().run()" 
        :class="{ 'bg-slate-400': editor.isActive('italic') }">
        <xdem-icon
          icon="bg-xdem-italic"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        @click="editor.chain().focus().toggleStrike().run()" 
        :class="{ 'bg-slate-400': editor.isActive('strike') }">
        <xdem-icon
          icon="bg-xdem-strike-through"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        @click="editor.chain().focus().toggleUnderline().run()" 
        :class="{ 'bg-slate-400': editor.isActive('underline') }">
        <xdem-icon
          icon="bg-xdem-underline"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        @click="editor.chain().focus().toggleCode().run()" 
        :class="{ 'bg-slate-400': editor.isActive('code') }">
        <xdem-icon
          icon="bg-xdem-inline-code"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        @click="editor.commands.focus().setParagraph()" 
        :class="{ 'bg-slate-400': editor.isActive('paragraph') }">
        <xdem-icon
          icon="bg-xdem-paragraph"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        :class="{ 'bg-slate-400': editor.isActive('heading', { level: 1 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()">
        <xdem-icon
          icon="bg-xdem-heading-1"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        :class="{ 'bg-slate-400': editor.isActive('heading', { level: 2 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
        <xdem-icon
          icon="bg-xdem-heading-2"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        :class="{ 'bg-slate-400': editor.isActive('heading', { level: 3 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
        <xdem-icon
          icon="bg-xdem-heading-3"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        :class="{ 'bg-slate-400': editor.isActive('bulletList') }"
        @click="editor.chain().focus().toggleBulletList().run()">
        <xdem-icon
          icon="bg-xdem-bullet-list"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        :class="{ 'bg-slate-400': editor.isActive('orderedList') }"
        @click="editor.chain().focus().toggleOrderedList().run()">
        <xdem-icon
          icon="bg-xdem-numbered-list"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        :class="{ 'bg-slate-400': editor.isActive('blockquote') }"
        @click="editor.chain().focus().toggleBlockquote().run()">
        <xdem-icon
          icon="bg-xdem-quote"
          size="28"/>
      </div>
      <div
        class="flex bg-slate-200 hover:bg-slate-300 rounded-md cursor-pointer"
        :class="{ 'bg-slate-400': editor.isActive('codeBlock') }"
        @click="editor.chain().focus().toggleCodeBlock().run()">
        <xdem-icon
          icon="bg-xdem-block-code"
          size="28"/>
      </div>
    </floating-menu>
  </div>

  <editor-content
    :editor="editor"
    :class="addClass"
  />
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import {
  BubbleMenu,
  Editor,
  EditorContent,
  FloatingMenu,
} from '@tiptap/vue-3'

export default {
  name: 'SmartEditor',
  components: {
    EditorContent,
    BubbleMenu,
    FloatingMenu,
  },

  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    setValue: {
      type: Function,
      required: false
    },
    addClass: {
      type: String,
      required: false,
      default: ''
    },
  },

  data() {
    return {
      editor: null,
      ph: '',
    }
  },

  computed: {
    // contentEditor() {
    //   return this.editor.getText()
    // },
  },

  mounted() {
    this.ph = this.placeholder
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: ({ editor }) => {
            return editor.isEmpty ? this.ph : '';
          },
        }),
      ],
      content: this.text,
      onUpdate: () => {
        const newHTML = this.editor.getHTML();
        this.setValue(newHTML);
        this.checkPlaceholder();
      },
    });
    this.checkPlaceholder();
  },

  methods: {
    checkPlaceholder() {
      this.editor.view.updateState(this.editor.view.state);
    },
  },
  beforeUnmount() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
}
</script>

<style>
/* Basic editor styles */
.tiptap.ProseMirror {
  min-height: fit-content!important;
}
.tiptap {
  :first-child {
    margin-top: 0;
  }

  /* List styles */
  ul, ol {
    list-style: revert;
  }
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  /* Code and preformatted text styles */
  code {
    background-color: var(--purple-light);
    border-radius: 0.4rem;
    color: var(--purple);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: var(--secondary);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;
    white-space: pre-wrap;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid var(--secondary);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--secondary);
    margin: 2rem 0;
  }
}

/* Bubble menu */
.bubble-menu {
  background-color: var(--white);
  border-radius: 0.7rem;
  box-shadow: var(--box-shadow-dark);
  padding: 0.2rem;

  button {
    background-color: unset;

    &:hover {
      background-color: var(--secondary);
    }

    &.is-active {
      background-color: var(--purple);

      &:hover {
        background-color: var(--purple-light);
      }
    }
  }
}

/* Floating menu */
.floating-menu {
  background-color: var(--white);
  padding: 0.1rem;
  border-radius: 0.5rem;

  button {
    background-color: unset;
    padding: 0.275rem 0.425rem;
    border-radius: 0.3rem;

    &:hover {
      background-color: var(--secondary);
    }

    &.is-active {
      background-color: var(--white);
      color: var(--purple);

      &:hover {
        color: var(--purple-light);
      }
    }
  }
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
.tiptap p.is-empty::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
</style>