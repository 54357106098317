<template>
  <span
    class="inline-block bg-cover bg-no-repeat"
    :class="`${icon} ${custom}`"
    :style="`width:${size}px; height:${size}px`"
  />
</template>

<script>

export default {
  name: 'XdemiaIcon',
  props: {
    icon: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String,
      required: false,
      default: '24',
    },
    custom: {
      type: String,
      required: false,
      default: 'cursor-pointer',
    }
  },

}
</script>
