<template>
  <div
    v-show="isVisible"
    class="relative z-50"
    @click.self="close">
    <div
      class="fixed inset-0 bg-gray bg-opacity-75 transition-opacity"
      aria-hidden="true"
      @click="close">
    </div>
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
          <div class="flex justify-between flex-row px-4 py-3 border-b border-gray bg-slate-100">
            <p class="text-xl font-medium fw-400 text-slate-500">
              Share to your timeline
            </p>
            <xdem-icon
              icon="bg-xdem-close"
              size="24"
              @click="close"
            />
          </div>
          <div class="flex flex-col px-2 py-2 sm:px-5 sm:py-4 sm:mt-0 max-h-[500px] overflow-auto overflow-x-hidden">
            <div class="flex flex-row items-center pb-4 sm:pb-0">
              <img
                :src="userData.avatar"
                :title="userData.name"
                class="w-[32px] h-[32px] rounded-full bg-cover bg-center bg-no-repeat">
              <div class="flex flex-col ml-2">
                <div class="flex flex-row items-center justify-start">
                  <p class="text-base font-medium text-slate-700 line-clamp-1">
                    {{ userData.name }}
                  </p>
                  <xdem-icon
                    v-show="userData.isVerified"
                    icon="bg-xdem-verified"
                    size="12"
                    custom="ml-1"/>
                  <div
                    v-show="userData.headline"
                    class="rounded-l-[30px] rounded-r-[30px] bg-[#089dd9] ml-1">
                    <p class="text-xs font-medium text-white px-2 py-0.5">
                      {{ userData.headline }}
                    </p>
                  </div>
                </div>
                <div class="flex flex-row items-center">
                  <p class="text-xs font-normal text-primary-dark">
                    {{ userData.current_institute }}
                  </p>
                  <p
                    v-show="userData.headline && userData.current_institute"
                    class="px-1">|</p>
                  <p
                    v-show="userData.current_organisation"
                    class="text-xs font-normal text-primary-dark">
                    {{ userData.current_organisation }}
                  </p>
                </div>
              </div>
            </div>
            <smart-editor
              :key="`share-content`"
              :text="content"
              placeholder="Create a post"
              :set-value="val => content = val"
              add-class="my-4 min-h-[40px] min-h-fit"
            />
            <div
              v-if="data.media"
              :style="{ backgroundImage:
                `url(${data.media})`}"
              class="flex items-end justify-end bg-cover bg-no-repeat w-full min-h-[300px]">
              <div class="bg-secondary--500 flex justify-center items-center h-[40px] w-full">
                <p class="text-md font-normal text-white">
                  {{ data.title }}
                </p>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-3 py-3 sm:flex sm:flex-row-reverse border-t border-gray">
            <button
              type="button"
              class="inline-flex w-full justify-center bg-primary hover:bg-primary--600 active:bg-primary--700 rounded-md px-3 py-2 shadow-sm sm:ml-3 sm:w-auto sm:min-w-[100px]"
              @click="save">
              <p class="text-base font-medium text-white">
                Post
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '@/store';
import SmartEditor from '@/components/SmartEditor';

export default {
  name: 'ShareToTimelineModal',
  components: {
    SmartEditor,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    data: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      required: false
    },
  },
  data() {
    return {
      content: '',
    };
  },
  computed: {
    userData() {
      return useAuthStore().user;
    },
  },
  methods: {
    async save() {
      await this.$http.put('/share', {
        type: 'newsroom',
        shareId: this.data.uuid,
        shareTo: 'toMe',
        parties: [],
        hashtags: null,
        content: this.content,
        mentions: [],
        isShareContent: false,
      }).then((res) => {
        if(res) {
          this.$toast.open({
            message: 'Success',
            type: 'success',
          });
          this.close();
        }
      }).catch(() => {
        this.$toast.open({
            message: 'Failed to share as a post',
            type: 'error',
          });
      });
    }
  }
};
</script>
