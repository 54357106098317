<template>
  <header-menu />
  <div class="flex h-[468px] min-h-full w-10/12 xl:w-7/12 m-auto rounded-lg bg-light shadow-lg overflow-hidden">
    <div class="flex flex-row w-full">
      <div class="hidden lg:flex flex-col justify-end w-1/2 px-8 py-8 bg-login-image bg-cover bg-no-repeat">
        <p class="text-[32px] semibold text-light mt-2">
          Xdemia Newsroom
        </p>
      </div>
      <form
        class="flex flex-col min-h-fit justify-between w-full lg:w-1/2 px-8 lg:px-16 mt-16 mb-8"
        @submit.prevent="login">
        <div class="flex flex-col">
          <p class="text-[32px] font-semibold text-primary mb-[8px]">
            Sign in
          </p>
          <p class="text-lg font-normal text-primary-dark">
            Sign in with your Xdemia account
          </p>
          <div class="flex flex-col gap-2 mt-4">
            <div class="flex flex-col">
              <label
                for="email"
                class="text-sm font-normal pb-[4px] leading-5"
                :class="errors?.email?.[0] ? 'text-red-500' : 'text-primary-dark'"
              >
                Your email address
              </label>
              <input
                v-model="email"
                placeholder="yourname@youremail.xyz"
                type="email"
                name="email"
                class="p-2 border rounded-md focus:outline-none"
                :class="errors?.email?.[0] ? 'border-red-500' : 'border-[#8D9AA0]'"
                @keydown="clearErrors('email')">
              <span
                v-if="errors?.email"
                class="text-sm text-center text-red-500 my-1 h-3">
                {{ errors.email[0] }}
              </span>
            </div>
            <div class="flex flex-col mt-2">
              <label
                for="password"
                class="text-sm font-normal pb-[4px] leading-5"
                :class="errors?.email?.[0] ? 'text-red-500' : 'text-primary-dark'"
              >
                Password
              </label>
              <div class="relative rounded-md">
                <input
                  v-model="password"
                  placeholder="your password"
                  :type="showPassword ? 'text' : 'password'"
                  name="password"
                  class="block w-full rounded-md border py-2 p-2 pr-20 text-gray-900 focus:outline-none"
                  :class="errors?.password?.[0] ? 'border-red-500' : 'border-[#8D9AA0]'"
                  @keydown="clearErrors('password')">
                <div class="absolute inset-y-0 right-0 flex items-center">
                  <div
                    class="text-primary font-bold rounded"
                    @click="showPassword = !showPassword">
                    <p class="text-sm primary text-primary cursor-pointer mr-2">{{ showPassword ? 'Hide' : 'Show' }}</p>
                  </div>
                </div>
              </div>
              <span
                v-if="errors?.password"
                class="text-sm text-center text-red-500 my-1 h-3">
                {{ errors.password[0] }}
              </span>
            </div>
          </div>
          <span
            v-if="errors?.notJournalist"
                class="text-center text-red-500 mt-1 h-3">
            {{ 'Only journalist of Xdemia who can login!' }}
          </span>
        </div>
        <div class="flex flex-col items-center justify-center w-full">
          <button
            type="submit"
            class="text-white font-bold py-2 px-4 rounded mt-4 w-full"
            :class="loading ? 'bg-slate-300' : 'bg-primary hover:bg-primary--600'">
            <p class="text-base primary text-light">Sign in</p>
          </button>
          <a
            href="https://xdemia.com/auth/password/reset"
            class="text-sm font-semibold text-primary pt-4 leading-5">
            Forgot password?
          </a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '@/store';
import HeaderMenu from '../Layout/HeaderMenu.vue';

export default {
  name: 'LoginPage',

  components: {
    HeaderMenu,
  },

  data() {
    return {
      email: "",
      password: "",
      errors: {},
      showPassword: false,
      loading: false,
    }
  },

  methods: {
    async login() {
      this.loading = true;
      const param = {
        email: this.email,
        password: this.password,
        newsroomWeb: true,
      }
      await this.$http.post('/auth/login', JSON.stringify(param)).then((res) => {
        localStorage.setItem("accessToken", res.data.accessToken);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        useAuthStore().setUser(res.data.user);
        if (this.$router.currentRoute._value.query.nextUrl) this.$router.push(`${this.$router.currentRoute._value.query.nextUrl}`)
        else this.$router.push('/')
        this.loading = false;
      }).catch((err) => {
        this.errors = err?.response?.data?.errors;
        console.log(this.errors, err)
        this.loading = false;
      });
    },
    clearErrors(field) {
      if(field === 'email') delete this.errors[field];
      else if(field === 'password') delete this.errors[field];
    }
  },
}
</script>
