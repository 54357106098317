import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import '@/assets/styles/index.css';
import { createPinia } from 'pinia';
import axios from 'axios'
import VueAxios from 'vue-axios';
import XdemiaIcon from '@/components/components/XdemiaIcon';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';


const app = createApp(App);
app.component('xdem-icon', XdemiaIcon);
app.use(router);
const pinia = createPinia();
pinia.use(({ store }) => {
  store.$http = app.config.globalProperties.$http;
});
app.use(pinia);
app.use(VueAxios, axios);
app.use(ToastPlugin);

axios.defaults.baseURL = process.env.VUE_APP_API_HTTP;
axios.defaults.headers.common.Accept = '*/*';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Content-Type'] = 'application/json';
// axios.defaults.headers.langPreferred = getCookie('langPrefs') ? getCookie('langPrefs') : 'en';
window.axiosInterceptor = axios.interceptors.request.use(async (config) => {
  try {
    // const accessToken = await JWT.retrieve(); 
    const accessToken = localStorage.getItem("accessToken");
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${accessToken}`;
  } catch (e) {
    // eslint-disable-next-line no-param-reassign
    config.baseURL += '/auth/';
  }
  return config;
});

axios.interceptors.response.use(null, async (error) => {
  const originalRequest = error.config;

  if (error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const response = await axios.post('/auth/refreshToken', { refreshToken: refreshToken });
      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);
      originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;
      return axios(originalRequest);
    } catch (refreshError) {
      router.push('/login');
    }
  }

  let path = '';
  switch (error.response?.status) {
    case 401: path = '/login'; break;
    case 403: path = '/'; break;
    case 404: path = '/404'; break;
    default: path = ''; break;
  }
  if (path) {
    router.push(path);
  }
  return Promise.reject(error);
});

app.mount('#app');
