<template>
  <layout>
    <div
      v-show="!preview"
      class="max-w-xs sm:max-w-md md:max-w-2xl xl:max-w-3xl xl:max-w-4xl mx-auto bg-gray w-full">
      <div class="flex items-center justify-between mb-6 cursor-pointer">
        <div
          @click="isChanged() ? $router.push('/') : modalCancel()"
          class="flex items-center cursor-pointer">
          <xdem-icon
            icon="bg-xdem-arrow-left"
            size="26"/>
          <p class="text-base font-normal text-primary ml-2">
            Cancel
          </p>
        </div>
      </div>
      <div class="flex flex-col bg-light rounded-md px-2 sm:px-5 py-5 mb-5">
        <div class="flex flex-col sm:flex-row items-center justify-between">
          <div
            v-if="userData"
            class="flex flex-row items-center justify-center pb-4 sm:pb-0">
            <img
              :src="userData.avatar"
              :title="userData.name"
              class="w-[32px] h-[32px] rounded-full bg-cover bg-center bg-no-repeat">
            <div class="flex flex-col ml-2">
              <div class="flex flex-row items-center justify-start">
                <p class="text-sm font-bold text-slate-700 line-clamp-1">
                  {{ userData.name }}
                </p>
                <xdem-icon
                  v-if="userData.isVerified"
                  icon="bg-xdem-verified"
                  size="12"
                  custom="ml-1"/>
              </div>
              <div class="flex flex-row items-center justify-center">
                <p class="text-sm font-normal text-primary-dark">
                  {{ !isEdit ? 'Creating an article' : 'You are viewing your artilce'  }}
                </p>
              </div>
            </div>
          </div>
          <div class="flex flex-row">
            <div
              v-if="!model.isPublish"
              class="flex items-center justify-center mr-4 cursor-pointer">
              <button
                :disabled="isChanged()"
                class="bg-none px-3"
                @click="saveToDraft()">
                <p
                  :class="isChanged() ? 'text-[#BDBDBD]' : 'text-primary'"
                  class="text-base font-medium">
                  Save as draft
                </p>
              </button>
            </div>
            <div class="flex items-center justify-center mr-4 cursor-pointer">
              <button
                class="bg-none px-3"
                :disabled="!isEdit && isChanged()"
                @click="preview = true">
                <p
                  :class="!isEdit && isChanged() ? 'text-[#BDBDBD]' : 'text-primary'"
                  class="text-base font-medium">
                  Preview
                </p>
              </button>
            </div>
            <div
              v-if="isEdit && model.isPublish"
              class="flex items-center justify-center mr-4 cursor-pointer">
              <button
                class="bg-none px-3"
                @click="modalUnpublish()">
                <p class="text-primary text-base text-primary font-medium">
                  Unpublish
                </p>
              </button>
            </div>
            <div class="flex items-center justify-center cursor-pointer">
              <button
                class="rounded ml-1 py-2 px-6"
                :disabled="isChanged()"
                :class="isChanged() ? 'bg-[#BDBDBD]' : 'bg-primary hover:bg-primary--600 active:bg-primary--700'"
                @click="saveAndPublish()">
                <p class="text-base text-white font-semibold">
                  {{ model.isPublish ? 'Update' : 'Publish' }}
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="!preview"
        class="min-h-screen bg-white rounded-t-md px-2 sm:px-16 py-8">
        <input
          :key="`title-${model.uuid}`"
          v-model="model.title"
          placeholder="Title"
          class="text-[32px] font-semibold border-0 mb-6 min-h-[40px] w-full outline-none"
          maxlength="190"
        />
        <!-- hide input -->
        <input
          :id="`cover`"
          type="file"
          :accept="allowedFile"
          hidden
          @change="(e) => attachDocument(e)">
        <!-- end -->
        <div
          v-if="model.media"
          class="min-w-full h-fit relative flex items-center justify-center">
          <video
            v-if="['video/mp4', 'video/ogg', 'video/webm'].includes(model.media_type)"
            style="background: black;"
            class="max-h-[400px] min-h-[150px] w-auto rounded-lg"
            allow="accelerometer; clipboard-write; encrypted-media;"
            controls>
            <source
              :src="mediaUrl(model.media)"
              :type="model.media_type">
          </video>
          <img
            v-else
            :src="mediaUrl(model.media)"
            :alt="model.media_desc"
            class="max-h-[400px] min-h-[150px] w-auto rounded-lg">
          <div class="absolute right-0 top-0 flex">
            <button
              class="flex items-center bg-white hover:bg-primary--100 active:bg-primary--200 text-primary ring-1 ring-primary rounded px-2 h-[40px] mr-6 mt-6"
              @click="() => {
                deletedMedia.push(model.media);
                model.media = null }">
              <xdem-icon
                icon="bg-xdem-delete"
                size="24"/>
            </button>
          </div>
          <div class="absolute right-0 bottom-0 flex">
            <button
              class="bg-white hover:bg-primary--100 active:bg-primary--200 text-primary ring-1 ring-primary rounded py-2 px-4 h-[40px] mr-6 mb-6"
              @click="chooseFiles('cover')">
              <p class="text-sm font-medium">Change image or media</p>
            </button>
          </div>
        </div>
        <div v-else class="flex items-center justify-center bg-cover-image bg-cover bg-no-repeat min-h-[400px] rounded-md">
          <button
            class="bg-white hover:bg-primary--100 active:bg-primary--200 rounded py-2 px-4"
            @click="chooseFiles('cover')">
            <p class="text-sm text-primary text-md font-medium ">Upload image or video</p>
          </button>
        </div>
        <input
          v-if="model.media"
          :key="`media_desc-${model.uuid}`"
          v-model="model.media_desc"
          placeholder="Add text for the image or video"
          class="text-[12px] font-normal text-center text-[#4F4F4F] border-0 mb-6 min-h-[40px] w-full outline-none flex justify-center text-center pt-3"
          maxlength="190"
        />
        <smart-editor
          :key="`content-${model.uuid}`"
          :text="model.content"
          placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          :set-value="val => model.content = val"
          add-class="mt-5 min-h-[250px] sm:min-h-[150px] lg:min-h-[110px]"
        />
        <!-- loop section -->
        <div
          v-for="(item, idx) in model.sections"
          :key="idx"
          class="relative mt-6">
          <input
            :key="`title-${model.uuid}-${idx}`"
            v-model="item.title"
            placeholder="Subtitle"
            class="text-[20px] font-semibold text-[#333333] border-0 mb-4 min-h-[40px] w-full outline-none"
            maxlength="190"
          />
          <div
            class="absolute w-[36px] h-[36px] bg-white rounded-full shadow-md top-0 flex items-center justify-center right-0 sm:right-[-44px] cursor-pointer"
            @click="removeSection(idx, item.id)">
            <xdem-icon
              icon="bg-xdem-delete"
              size="24"
              title="Remove section"/>
          </div>
          <smart-editor
            :key="`content-${model.uuid}-${idx}`"
            :text="item.content"
            placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
            :set-value="val => model.sections[idx].content = val"
            add-class="min-h-[250px] sm:min-h-[150px] lg:min-h-[140px]"
          />
          <smart-editor
            :key="`url-${model.uuid}-${idx}`"
            :text="item.url"
            placeholder="URL links"
            :set-value="val => model.sections[idx].url = val"
            add-class="min-h-[80px] sm:min-h-[40px] lg:min-h-[40px] my-4"
          />
          <!-- hide input -->
          <input
            :id="`cover-${idx}`"
            type="file"
            :accept="allowedFile"
            hidden
            @change="(e) => attachDocument(e, idx)">
          <!-- end -->
          <div
            v-if="item.media"
            class="min-w-full h-fit relative flex items-center justify-center">
            <video
              v-if="['video/mp4', 'video/ogg', 'video/webm'].includes(item.media_type)"
              style="background: black;"
              class="max-h-[400px] min-h-[150px] w-auto rounded-lg"
              allow="accelerometer; clipboard-write; encrypted-media;"
              controls>
              <source
                :src="mediaUrl(item.media)"
                :type="item.media_type">
            </video>
            <img
              v-else
              :src="mediaUrl(item.media)"
              :alt="item.media_desc"
              class="max-h-[400px] min-h-[150px] w-auto rounded-lg">
            <div class="absolute right-0 top-0 flex">
              <button
                class="flex items-center bg-white hover:bg-primary--100 active:bg-primary--200 text-primary ring-1 ring-primary rounded px-2 h-[40px] mr-6 mt-6"
                @click="() => {
                  deletedMedia.push(model.sections[idx].media);
                  model.sections[idx].media = null }">
                <xdem-icon
                  icon="bg-xdem-delete"
                  size="24"/>
              </button>
            </div>
            <div class="absolute right-0 bottom-0 flex">
              <button
                class="bg-white hover:bg-primary--100 active:bg-primary--200 text-primary ring-1 ring-primary rounded py-2 px-4 h-[40px] mr-6 mb-6"
                @click="chooseFiles(`cover-${idx}`)">
                <p class="text-sm font-medium">Change image or media</p>
              </button>
            </div>
          </div>
          <template v-else>
            <button
              class="bg-primary-light hover:bg-primary--200 active:bg-primary--300 text-primary rounded py-2 px-4 mb-4"
              @click="chooseFiles(`cover-${idx}`)">
              <p class="text-sm font-normal">Upload image or video</p>
            </button>
          </template>
          <input
            v-if="item.media"
            :key="`media_desc-${model.uuid}-${idx}`"
            v-model="item.media_desc"
            placeholder="Add text for the image or video"
            class="text-[12px] font-normal text-[#4F4F4F] text-center border-0 my-3 min-h-[24px] w-full outline-none"
            maxlength="190"
          />
        </div>
        <!-- new section -->
        <button
          class="bg-white hover:bg-primary--200 active:bg-primary--300  text-primary ring-1 ring-primary rounded ml-1 py-2 px-4 mt-12"
          @click="addNewSection">
          <p class="text-sm font-medium">New section</p>
        </button>
      </div>
      <div class="bg-white rounded-b-md px-2 sm:px-16 py-8">
        {{ totalWords }} of 500 words
      </div>
    </div>
    <preview-newsroom
        v-if="preview"
        :key="model.uuid"
        :is-configurator="true"
        :data="model"
        :user-data="userData"
        :base-url="baseMediaUrl"
        :save="saveToDraft"
        :publish="saveAndPublish"
        :close="() => preview = false"
      />
    <popup-modal
      v-if="modalAttributes"
      :is-visible="showModal"
      :modal-attributes="modalAttributes"
      :close="() => modal = false"
    />
</layout>
</template>

<script>
import { useAuthStore } from '@/store';
import { ALLOWED_IMAGE, ALLOWED_VIDEO, ALLOWED_FILE } from '../lib/helper';
import Newsroom from './models/Newsroom';
import Layout from '@/components/Layout/LayoutMain';
import SmartEditor from '@/components/SmartEditor';
import PreviewNewsroom from './components/PreviewNewsroom.vue';
import PopupModal from '@/components/components/PopupModal.vue';
import Section from './models/Section';

export default {
  name: 'ConfiguratorPage',
  components: {
    Layout,
    SmartEditor,
    PreviewNewsroom,
    PopupModal,
  },
  
  data() {
    return {
      preview: false,
      model: new Newsroom(),
      model2: new Newsroom(), // to camparing whether the data changed or empty
      modal: false,
      modalAttributes: null,
      finished: false,
      isEdit: false,
      baseMediaUrl:  process.env.VUE_APP_API_HTTP+'/storage/tmp/',
      allowedFile: ALLOWED_FILE,
      deletedMedia: [],
    };
  },
  computed: {
    showModal() {
      return this.modal;
    },
    userData() {
      return useAuthStore().user;
    },
    totalWords() {
      let content = '';
      this.model.sections.forEach((item) => {
        content = content+' '+item.title+' '+item.content+' '+item.media_desc+' ';
      });
      content = content.replace(/<[^>]*>/g, '');
      return content.trim().split(/\s+/).filter(word => word).length;
    },
  },
  created() {
    if(this.$route.params.uuid) {
      this.isEdit = true;
      this.getEditData(this.$route.params.uuid);
    }
  },
  methods: {
    removeSection(idx, id) {
      this.model.sections.splice(idx, 1);
      this.model.deleted_sections = this.model.deleted_sections ? [...this.model.deleted_sections, id] : [id];
    },
    isEmptyOrFalse(obj, excludeKeys = ['uuid', 'slug', 'updatedDate']) {
      if (typeof obj !== 'object' || obj === null) {
        return !obj;
      }
      if (Array.isArray(obj)) {
        return obj.every(item => this.isEmptyOrFalse(item, excludeKeys));
      }
      return Object.keys(obj).every(key => {
        if (excludeKeys.includes(key)) {
          return true;
        }
        return this.isEmptyOrFalse(obj[key], excludeKeys);
      });
    },
    isEqual(obj1, obj2) {
      if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return obj1 === obj2;
      }
      if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false;
      }
      return Object.keys(obj1).every(key => {
        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
          return this.isEqual(obj1[key], obj2[key]);
        }
        return obj1[key] === obj2[key];
      });
    },
    isChanged() {
      let obj1 = this.model;
      let obj2 = this.model2;
      return this.isEqual(obj1, obj2) && !this.isEmptyOrFalse(obj1) || this.isEmptyOrFalse(obj1);
    },
    isValidUrl(url) {
      const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      return !!pattern.test(url);
    },
    mediaUrl(media) {
      return media.split("/").length === 1 ? this.baseMediaUrl+media : media;
    },
    chooseFiles(id) {
      document.getElementById(id).click()
    },
    attachDocument(e, idx) {
      if (!ALLOWED_IMAGE.includes(e.target.files[0].type) && !ALLOWED_VIDEO.includes(e.target.files[0].type)) {
        this.$toast.open({
            message: 'File type not allowed.',
            type: 'error',
          });
        return;
      }

      if (idx >= 0) {
        const sections = this.model.sections;
        if(sections[idx].media) {
          this.deletedMedia.push(sections[idx].media);
        }
        sections[idx].media = URL.createObjectURL(e.target.files[0]);
        sections[idx].media_type = e.target.files[0].type;
        this.model = {...this.model, sections: sections}
      } else {
          if(this.model.media) {
            this.deletedMedia.push(this.model.media);
          }
          this.model = {...this.model,
            media: URL.createObjectURL(e.target.files[0]),
            media_type: e.target.files[0].type};
      }
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      this.$http.post('/newsroom/media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (idx >= 0) {
        const sections = this.model.sections;
        sections[idx].media = res.data.media
        this.model = {...this.model, sections: sections}
      } else {
        this.model = {...this.model, media: res.data.media};
      }
      })
      .catch(() => {
        this.model.media = null
        this.$toast.open({
            message: 'Failed uploading file.',
            type: 'error',
          });
      });
    },
    async deleteMedia(name) {
      const fileName = name.substring(0).split('/')[name.substring(0).split('/').length -1];
      if(!fileName) return;
      await this.$http.delete(`/newsroom/media/${fileName}`);
    },
    addNewSection() {
      this.model = {...this.model, sections: [...this.model.sections, new Section]};
    },
    modalCancel() {
      this.modalAttributes = {
        header: 'Leave this site',
        title: 'Do you want to save?',
        message: 'You will found this article on your draft',
        button: [
          {
            text: 'Yes, save',
            background: 'bg-primary text-white',
            function: () => {
              this.saveBeforeLeave()
            },
          },
          {
            text: 'Exit',
            background: 'bg-primary-light text-primary',
            function: () => {
              this.$router.push('/');
            },
          },
          {
            text: 'Cancel',
            background: 'text-primary',
            function: () => this.modal = false,
          }
        ]
      };
      this.modal = true;
    },
    modalExit() {
      this.modalAttributes = {
        header: 'Leave',
        title: 'Are you sure?',
        message: 'Make sure your articles are saved before you leave',
        button: [
          {
            text: 'Yes',
            background: 'bg-primary text-white',
            function: () => {
              this.$router.push('/');
            },
          },
          {
            text: 'No',
            background: 'bg-primary-light text-primary',
            function: () => this.modal = false
          },
        ]
      };
      this.modal = true;
    },
    modalUnpublish() {
      this.modalAttributes = {
        header: 'Unpublish article',
        title: 'Are you sure?',
        message: 'You will found this article on your draft',
        button: [
          {
            text: 'Yes',
            background: 'bg-primary text-white',
            function: () => {
              this.model.is_publish = false;
              this.saveToDraft().then(() => {
                this.$router.push('/');
              })
            },
          },
          {
            text: 'No',
            background: 'bg-primary-light text-primary',
            function: () => this.modal = false
          },
        ]
      };
      this.modal = true;
    },
    async saveToDraft() {
      const savedTo = this.model.is_publish ? 'to publish' : 'saved to draft';
      if(this.isEdit) {
        await this.$http.post('/newsroom/update',  JSON.stringify(this.model))
        .then((res) => {
          if(res) {
            this.$toast.open({
              message: `Successfully ${savedTo}`,
              type: 'default',
              position: 'top'
            });
            this.getEditData(res.data.uuid)
          }
        })
        .catch((err) => {
          const {data} = err.response;
          this.$toast.open({
            message: data.errors ? data.errors.title[0] : data.message,
            type: 'default',
            position: 'top'
          });
        });
      } else {
        await this.$http.post('/newsroom/create',  JSON.stringify(this.model))
        .then((res) => {
          if(res) {
            this.$toast.open({
              message: `Successfully ${savedTo}`,
              type: 'default',
              position: 'top'
            });
            this.isEdit = true;
            this.getEditData(res.data.uuid)
          }
        })
        .catch((err) => {
          const {data} = err.response;
          this.$toast.open({
            message: data.errors ? data.errors.title[0] : data.message,
            type: 'default',
            position: 'top'
          });
        });
      }
      if(this.deletedMedia.length) {
        this.deletedMedia.forEach(item => {
          this.deleteMedia(item);
        });
      }
    },
    saveBeforeLeave() {
      this.model2 = {...this.model}
      this.finished = true;
      this.saveToDraft().then(() => {
        this.$router.push({name: 'Home', query: {tab:this.model.is_publish?1:0}});
      })
    },
    saveAndPublish() {
      this.model2 = {...this.model}
      this.model.is_publish = true;
      this.finished = true;
      this.saveToDraft().then(() => {
        this.$router.push({name: 'Home', query: {tab:this.model.is_publish?1:0}});
      })
    },
    async getEditData(uuid) {
      await this.$http.get(`/newsroom/edit/${uuid}`).then((res) => {
        this.model = res.data;
        this.model2 = JSON.parse(JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err)
      });
    },
  },
  beforeRouteLeave (to) {
    if (this.isChanged()) return true
    if (this.userData === null) return true
    if (to.path === '/404') this.$router.push('/')
    if (!this.modal && !this.finished) {
      this.modalAttributes = {
        header: 'Leave this site',
        title: this.model.is_publish ? 'Do you want to save and publish?' : 'Do you want to save?',
        message: this.model.is_publish ? '' : 'You will found this article on your draft',
        button: [
          {
            text: 'Yes, save',
            background: 'bg-primary text-white',
            function: () => {
              this.saveBeforeLeave()
              this.$router.push(to)
            },
          },
          {
            text: 'Exit',
            background: 'bg-primary-light text-primary',
            function: () => {
              this.$router.push(to)
            },
          },
          {
            text: 'Cancel',
            background: 'text-primary',
            function: () => this.modal = false,
          }
        ]
      };
      this.modal = true;
      return false
    }
    return true
  }
}
</script>
