import { defineStore } from 'pinia'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: {},
    newsroom: {}
  }),
  actions: {
    setUser(user) {
      this.user = {...user};
    },
    async setUserData () {
      return await this.$http.get(`users/me`)
        .then((response) => {
          this.user = {...response.data}
          return true;
        })
        .catch(() => {
          this.logout()
          return false;
        })
    },
    logout() {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      this.user = null;
    },
  },
});