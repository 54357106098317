const ALLOWED_IMAGE = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/bmp',
  'image/x-icon'
];

const ALLOWED_VIDEO = [
  'video/mp4',
  'video/webm',
  'video/ogg'
];

const ALLOWED_FILE = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'video/mp4',
  'video/ogg',
  'video/webm'
];

export {
  ALLOWED_IMAGE,
  ALLOWED_VIDEO,
  ALLOWED_FILE,
}