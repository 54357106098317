<template>
  <layout>
    <div
      class="absolute h-[48px] lg:h-[58px] w-[100px] 3xl:w-[240px] ml-0 sm:ml-8 md:ml-0 flex items-center max-w-xs sm:max-w-md md:max-w-2xl xl:max-w-3xl xl:max-w-4xl rounded-[48px] bg-white shadow-sm cursor-pointer"
      @click="this.$router.push('/')">
      <div class="flex items-center px-4">
        <xdem-icon
          icon="bg-xdem-arrow-left-black"
          size="26"/>
          <p class="text-base font-normal text-[#505252] ml-1">
            Back
          </p>
      </div>
    </div>
    <div class="max-w-xs sm:max-w-md md:max-w-2xl xl:max-w-3xl xl:max-w-4xl mx-auto bg-gray w-full mt-20 lg:mt-0">
      <preview-newsroom
        v-if="newsroom"
        :is-configurator="false"
        :data="newsroom"
        :edit="editData"
        :user-data="userData"
        :remove="modalDelete"
        :publish="modalPublish"
        :share-data="() =>{
          shareModalData = {...newsroom}
          modal = true
        }"
      />
    </div>
    <popup-modal
      v-if="modalAttributes"
      :is-visible="showModal"
      :modal-attributes="modalAttributes"
      :close="() => modal = false"
    />
    <share-to-timeline-modal
      v-if="shareModalData"
      :key="shareModalData.uuid"
      :is-visible="showModal"
      :data="shareModalData"
      :close="() => {
        modal = false
        shareModalData = null
      }"
    />
</layout>
</template>

<script>
import { useAuthStore } from '@/store';
import Layout from '@/components/Layout/LayoutMain';
import PreviewNewsroom from './components/PreviewNewsroom.vue';
import PopupModal from '@/components/components/PopupModal.vue';
import ShareToTimelineModal from './components/ShareToTimelineModal.vue';

export default {
  name: 'NewsroomPage',
  components: {
    Layout,
    PreviewNewsroom,
    PopupModal,
    ShareToTimelineModal,
  },
  data() {
    return {
      newsroom: null,
      modal: false,
      modalAttributes: null,
      shareModalData: null,
    };
  },
  computed: {
    showModal() {
      return this.modal;
    },
    userData() {
      return useAuthStore().user;
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      await this.$http.get(`/newsroom/edit/${this.$route.params.uuid}`).then((res) => {
        this.newsroom = res.data;
      })
      .catch((err) => {
        console.log(err)
      });
    },
    editData() {
      this.$router.push({ path: `/configurator/${this.newsroom.uuid}`})
    },
    modalDelete() {
      this.modalAttributes = {
        header: '',
        title: 'Are you sure?',
        message: 'Delete your article',
        button: [
          {
            text: 'Yes',
            background: 'bg-primary text-white',
            function: () => {
              this.deleteData()
            },
          },
          {
            text: 'No',
            background: 'bg-primary-light text-primary',
            function: () => this.modal = false,
          }
        ]
      };
      this.modal = true;
    },
    deleteData() {
      this.$http.delete(`/newsroom/delete/${this.newsroom.uuid}`).then((res) => {
        if(res) {
          this.$toast.open({
            message: this.newsroom.title+' deleted',
            type: 'error',
          });
          this.$router.push('/')
        }
      });
    },
    modalPublish() {
      this.modalAttributes = {
        header: 'Publish article',
        title: 'Are you sure?',
        message: 'Don’t forget to checking up your article before publish',
        button: [
          {
            text: 'Yes',
            background: 'bg-primary text-white',
            function: () => {
              this.publishData()
            },
          },
          {
            text: 'Cancel',
            background: 'text-primary',
            function: () => this.modal = false,
          }
        ]
      };
      this.modal = true;
    },
    async publishData() {
      this.$http.get(`/newsroom/publish/${this.newsroom.uuid}`).then((res) => {
        if (res) {
          this.$toast.open({
            message: 'Successfully publish your article',
            type: 'default',
            position: 'top'
          });
          this.$router.push('/')
        }
      }).catch((err) => {
        console.log(err)
      });
    },
  },
}
</script>