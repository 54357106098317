<template>
  <div id="app" class="flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip bg-gray">
    <router-view :key="$route.path"/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {}
}
</script>
