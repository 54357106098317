<template>
  <layout>
    <div class="flex flex-col rounded-md gap-16 p-8 bg-light">
      <div class="flex justify-between items-center flex-wrap">
        <p class="text-[44px] font-bold text-slate-500 py-4 md:py-0">
          Your articles
        </p>
        <router-link
          :to="{ name: 'Configurator'}"
          class="bg-primary hover:bg-primary--600 active:bg-primary--700 rounded-md p-2 px-6">
          <p class="text-base font-bold text-white text-center">
            Create an article
          </p>
        </router-link>
      </div>
      <div class="flex flex-col gap-2">
        <div class="flex flex-col lg:flex-row item-center justify-between">
          <div class="flex flex-row items-center w-full pr-2">
            <div
              v-for="(item, idx) in listMenu"
              :key="idx"
              class="w-32 py-2 cursor-pointer"
              :class="`${activeMenu === idx ? 'border-b-2 border-primary' : ''}`"
              @click="activeMenu = idx">
              <p
                class="text-base text-center"
                :class="`${activeMenu === idx ? 'text-primary font-semibold' : 'text-slate-400 font-normal'}`">
                {{ item }}
              </p>
            </div>
          </div>
          <div class="flex flex-col md:flex-row items-center justify-start lg:justify-end my-2 lg:my-0">
            <div class="relative rounded-md">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2 h-[40px]">
                <xdem-icon
                  icon="bg-xdem-search"
                  size="20"
                />
              </div>
              <input
                id="searchInput"
                ref="search"
                v-model.trim="searchTerm"
                type="text"
                class="block w-[264px] w-fit rounded-md border-0 py-2 pl-10 pr-20 text-slate-700 placeholder:text-slate-500 bg-none ring-1 ring-primary focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-sm"
                placeholder="Search"
              >
            </div>
            <div
              class="relative inline-block text-left"
              @mouseover="hover=true; show=true"
              @mouseleave="hover=false">
              <button
                class="w-[90px] ring-1 ring-primary rounded-md py-2 ml-4 flex items-center justify-center my-2 md:my-0"
                :class="show ? 'bg-primary--100' : ''">
                <xdem-icon
                  icon="bg-xdem-sort"
                  size="20"
                />
                <p class="text-[16px] text-primary">Sort</p>
              </button>
              <div
                v-show="show"
                class="absolute right-0 z-10 mt-2 w-44 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-4">
                <div class="flex flex-row items-center">
                  <xdem-icon
                    icon="bg-xdem-sort"
                    size="20"
                  />
                  <p class="text-[16px] text-black">Sort</p>
                </div>
                <p class="text-[16px] text-black pt-2">Sort order</p>
                <div
                  v-for="(item, idx) in sortOrder"
                  :key="`sortBy-${idx}`"
                  class="px-2 hover:bg-primary-light rounded-lg my-1"
                  :class="sort === item.props ? 'bg-primary-light' : ''"
                  @click="show = false; sort = item.props">
                  <button class="block w-full py-1 text-left text-sm text-primary-dark flex flex-row items-center">
                    <div
                      class="w-[14px] h-[14px] rounded-full ring-1 ring-primary mr-2"
                      :class="sort === item.props ? 'bg-primary--700' : ''"
                    />
                    {{ item.title }}
                  </button>
                </div>
                <p class="text-[16px] text-black pt-1">Sort by</p>
                <div
                  v-for="(item, idx) in sortBy"
                  :key="`sortOrder-${idx}`"
                  class="px-2 hover:bg-primary-light rounded-lg my-1"
                  :class="sort_by === item.props ? 'bg-primary-light' : ''"
                  @click="show = false; sort_by = item.props">
                  <button class="block w-full py-1 text-left text-sm text-primary-dark flex flex-row items-center">
                    <div
                      class="w-[14px] h-[14px] rounded-full ring-1 ring-primary mr-2"
                      :class="sort_by === item.props ? 'bg-primary--700' : ''"
                    />
                    {{ item.title }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          ref="scrollContainer"
          class="flex flex-row flex-wrap justify-center lg:justify-start overflow-x-none overflow-y-scroll gap-4 w-full mt-2 card-content-height"
          @scroll="handleScroll">
          <template v-if="listItems.length">
            <card-newsroom-item
              v-for="(item, idx) in listItems"
              :key="item.uuid"
              :data="item"
              :skeleton="loading && idx === listItems.length-1"
              :can-share="listMenu[activeMenu] === 'Published'"
              :share-data="() =>{
                shareModalData = {...item}
                modal = true
              }"
              :remove="() => modalDelete(item)"
              :publish="() => modalPublish(item)"
              :un-publish="() => modalUnpublish(item)"/>
          </template>
          <template v-else>
            <card-newsroom-item
              v-for="i in [0]"
              :key="i"
              :skeleton="loading"/>
          </template>
          <div
            v-show="!loading && !listItems.length"
            class="flex flex-col items-center justify-center w-full p-12">
            <template v-if="searchTerm.length">
              <div class="flex w-[240px] h-[150px] bg-no-result bg-cover bg-no-repeat" />
              <p class="text-[24px] text-[#434D51] font-semibold pt-2 pb-4">We didn’t find any results</p>
              <p class="text-[16px] text-[#434D51] font-normal pt-2 pb-4">
                Make sure everything is spelled correctly or try different keywords
              </p>
            </template>
            <template v-else>
              <div class="flex w-[240px] h-[150px] bg-empty-image bg-cover bg-no-repeat" />
              <p class="text-[18px] text-[#434D51] pt-2 pb-4">Start your writing journey today!</p>
              <router-link
                :to="{ name: 'Configurator'}"
                class="w-[200px] bg-primary hover:bg-primary--600 active:bg-primary--700 rounded-md p-2 px-6">
                <p class="text-base font-bold text-white text-center">
                  Create an article
                </p>
              </router-link>
            </template>
          </div>
        </div>
      </div>
    </div>
    <share-to-timeline-modal
      v-if="shareModalData"
      :key="shareModalData.uuid"
      :is-visible="showModal"
      :data="shareModalData"
      :close="() => {
        modal = false
        shareModalData = null
      }"
    />
    <popup-modal
      v-if="modalAttributes"
      :is-visible="showModalConfirm"
      :modal-attributes="modalAttributes"
      :close="() => modalConfirm = false"
    />
</layout>
</template>

<script>
import _ from 'lodash';
import { useAuthStore } from '@/store';
import Layout from '@/components/Layout/LayoutMain';
import CardNewsroomItem from '@/components/components/CardNewsroomItem'
import ShareToTimelineModal from './components/ShareToTimelineModal.vue';
import PopupModal from '@/components/components/PopupModal.vue';

export default {
  name: 'CataloguePage',
  components: {
    Layout,
    CardNewsroomItem,
    ShareToTimelineModal,
    PopupModal,
  },
  
  data() {
    return {
      show: false,
      hover: false,
      sort: 'ASC',
      sort_by: 'title',
      user: [],
      activeMenu: this.$route.query.tab? Number(this.$route.query.tab) : 0,
      // listMenu: ['Drafts','Published','Archive'],
      listMenu: ['Drafts','Published'],
      sortOrder: [
        {
          props: 'ASC',
          title: 'A - Z'
        },{
          props: 'DESC',
          title: 'Z - A'
        }
      ],
      sortBy: [
        {
          props: 'title',
          title: 'Title'
        },{
        //   props: 'added',
        //   title: 'Date Added'
        // },{
          props: 'created_at',
          title: 'Date Created'
        },{
          props: 'updated_at',
          title: 'Date Modified'
        }
      ],
      searchTerm: '',
      loading: false,
      listItems: [],
      nextPageUrl: null,
      modal: false,
      shareModalData: null,
      tab: this.$route.query.tab,
      modalConfirm: false,
      modalAttributes: null,
    };
  },
  computed: {
    userData() {
      return useAuthStore().user;
    },
    showModal() {
      return this.modal;
    },
    showModalConfirm() {
      return this.modalConfirm;
    },
  },
  watch: {
    hover: _.debounce(function debounced() {
      if (!this.hover) {
        this.show = false
      }
    }, 500),
    sort: _.debounce(function debounced() {
      this.loadData();
    }, 500),
    sort_by: _.debounce(function debounced() {
      this.loadData();
    }, 500),
    activeMenu: _.debounce(function debounced() {
      this.listItems = [];
      this.loadData();
    }, 500),
    searchTerm: _.debounce(function debounced() {
      this.loadData();
    }, 500),
  },
  created() {
    this.loadData();
  },
  methods: {
    handleScroll() {
      const container = this.$refs.scrollContainer;
      const bottomOfContainer = container.scrollTop + container.clientHeight >= container.scrollHeight - 1;
      if (bottomOfContainer) {
        this.infiniteHandler(this.nextPageUrl);
      }
    },
    setFocussearch() {
      this.$refs.searchParty.focus();
    },
    escapeSearch() {
      this.searchTerm = '';
      this.fullWidthSearch = false;
    },
    async loadData() {
      // need to add filter sort using this.sort
      this.loading = true;
      let getDraft = this.activeMenu === 0 ? '&isDraft=true' : '';
      await this.$http.get(`/newsroom/search?configuratorWeb=true&searchTerm=${
        this.searchTerm}${getDraft}&sortBy=${this.sort_by}&sortOrder=${this.sort}`)
        .then((response) => {
          this.listItems = response.data.data;
          this.nextPageUrl = response.data.nextPageUrl;
          this.loading = false;
        });
    },
    async infiniteHandler(nextPage) {
      if (nextPage !== this.nextPageUrl || nextPage === null) {
        return;
      }
      this.nextPageUrl = null;
      this.loading = true;
      await this.$http.get(nextPage).then((response) => {
        this.listItems = this.listItems.concat(
          response.data.data,
        );
        this.nextPageUrl = response.data.nextPageUrl;
        if (!this.nextPageUrl) {
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
      setTimeout(() => {
        this.listItems = this.listItems.concat(this.listItems);
        this.nextPageUrl = null;
        this.loading = false;
      }, 5000);
    },
    modalDelete(item) {
      this.modalAttributes = {
        header: '',
        title: 'Are you sure?',
        message: 'Delete your article',
        button: [
          {
            text: 'Yes',
            background: 'bg-primary text-white',
            function: () => {
              this.deleteData(item)
            },
          },
          {
            text: 'No',
            background: 'bg-primary-light text-primary',
            function: () => this.modalConfirm = false,
          }
        ]
      };
      this.modalConfirm = true;
    },
    async deleteData(item) {
      await this.$http.delete(`/newsroom/delete/${item.uuid}`).then((res) => {
        if(res) {
          this.listItems = [...this.listItems.filter(x => x.uuid !== item.uuid)]
          this.modalConfirm = false
          this.$toast.open({
            message: item.title+' deleted',
            type: 'error',
          });
        }
      });
    },
    modalPublish(item) {
      this.modalAttributes = {
        header: 'Publish article',
        title: 'Are you sure?',
        message: 'Don’t forget to checking up your article before publish',
        button: [
          {
            text: 'Yes',
            background: 'bg-primary text-white',
            function: () => {
              this.publishData(item)
            },
          },
          {
            text: 'Cancel',
            background: 'text-primary',
            function: () => this.modalConfirm = false,
          }
        ]
      };
      this.modalConfirm = true;
    },
    async publishData(item) {
      await this.$http.get(`/newsroom/publish/${item.uuid}`).then((res) => {
        if (res) {
          this.listItems = [...this.listItems.filter(x => x.uuid !== item.uuid)]
          this.modalConfirm = false
          this.$toast.open({
            message: 'Successfully publish your article',
            type: 'default',
            position: 'top'
          });
        }
      }).catch((err) => {
        console.log(err)
      });
    },
    modalUnpublish(item) {
      this.modalAttributes = {
        header: 'Unpublish article',
        title: 'Are you sure?',
        message: 'This article will move to Drafts',
        button: [
          {
            text: 'Yes',
            background: 'bg-primary text-white',
            function: () => {
              this.unPublishData(item)
            },
          },
          {
            text: 'Cancel',
            background: 'text-primary',
            function: () => this.modalConfirm = false,
          }
        ]
      };
      this.modalConfirm = true;
    },
    async unPublishData(item) {
      await this.$http.get(`/newsroom/publish/${item.uuid}`).then((res) => {
        if (res) {
          this.listItems = [...this.listItems.filter(x => x.uuid !== item.uuid)]
          this.modalConfirm = false
          this.$toast.open({
            message: 'This article has been Unpublished and moved to Drafts',
            type: 'default',
            position: 'top'
          });
        }
      }).catch((err) => {
        console.log(err)
      });
    },
  },
}
</script>
