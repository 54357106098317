// src/router.js

import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/store';
import CataloguePage from '@/components/CataloguePage.vue';
import ConfiguratorPage from '@/components/ConfiguratorPage.vue';
import NewsroomPage from '@/components/NewsroomPage.vue';
import Login from '@/components/auth/LoginPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: CataloguePage,
  },
  {
    path: '/configurator',
    name: 'Configurator',
    component: ConfiguratorPage,
  },
  {
    path: '/configurator/:uuid',
    name: 'EditConfigurator',
    component: ConfiguratorPage,
  },
  {
    path: '/newsroom/:uuid',
    name: 'Newsroom',
    component: NewsroomPage,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  // Add more routes here as needed
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async(to, from, next) => {
  if (to.path === '/404') next('/')
  else if (to.path !== '/' && to.name !== 'Login' && localStorage.getItem("accessToken") === null) {
    next({ path: 'Login', query: { nextUrl: to.fullPath }})
  }
  else if (to.name === 'Login' && localStorage.getItem("accessToken") !== null) next({ name: 'Home' })
  else if (Object.keys(useAuthStore().user || {}).length === 0 && localStorage.getItem("accessToken")) {
    var success = await useAuthStore().setUserData()
    if (success) next()
  }
  else {
    next()
  }
})

export default router;
