<template>
  <div
    v-if="data"
    class="w-[320px] h-[383px] flex flex-col justify-between rounded-lg overflow-hidden mt-4 border border-xl border-[#C5CCCE]">
    <div
      @click="previewCard"
      class="flex flex-col cursor-pointer">
      <div class="overflow-hidden min-h-[180px]">
        <video
          v-if="['video/mp4', 'video/ogg', 'video/webm'].includes(data.media_type)"
          style="background: black;"
          class="h-[180px] object-cover w-full"
          allow="accelerometer; clipboard-write; encrypted-media;">
          <source
            :src="cover"
            :type="data.media_type">
        </video>
        <img
          v-else
          :src="cover"
          :alt="data.media_desc"
          class="h-[180px] object-cover w-full">
      </div>
      <div class="flex flex-col w-full h-full px-4">
        <p class="text-[13px] text-primary-dark font-normal text-slate-500 mt-4">
          {{ createDate }}
        </p>
        <p
          v-html="data.title"
          class="text-base font-bold text-slate-700 line-clamp-2 my-2" />
        <p
          v-html="data.content"
          class="text-sm font-normal text-slate-700 line-clamp-2" />
      </div>
    </div>
    <div class="flex flex-row justify-between px-4 pb-4">
      <div class="flex flex-row items-center">
        <div
          class="flex items-center justify-center cursor-pointer mr-4"
          @click="editData">
          <xdem-icon
            icon="bg-xdem-edit"
            size="18"
          />
          <p class="text-base font-medium fw-400 text-primary ml-1">
            Edit
          </p>
        </div>
        <!-- can only be deleted if data is Unpublish -->
        <!-- <div
          v-if="!canShare"
          class="flex items-center justify-center cursor-pointer"
          @click="remove">
          <xdem-icon
            icon="bg-xdem-delete"
            size="18"
          />
          <p class="text-base font-medium fw-400 text-primary ml-1">
            Delete
          </p>
        </div> -->
        <!-- <div
          v-if="canShare"
          class="flex items-center justify-center cursor-pointer"
          @click="shareData">
          <xdem-icon
            icon="bg-xdem-share"
            size="18"
          />
          <p class="text-base font-medium fw-400 text-primary ml-1">
            Share
          </p>
        </div> -->
      </div>
      <div
        v-if="!canShare"
        class="flex items-center justify-center cursor-pointer ring-1 ring-primary rounded-[34px]"
        @click="publish">
        <p class="text-base font-medium fw-400 text-primary px-3 py-2">
          Publish
        </p>
      </div>
      <div
        v-else
        class="flex items-center justify-center cursor-pointer ring-1 ring-primary rounded-[34px]"
        @click="unPublish">
        <p class="text-base font-medium fw-400 text-primary px-3 py-2">
          Unpublish
        </p>
      </div>
    </div>
  </div>
  <template v-if="skeleton">
    <div v-for="i in [0,1,2]"
      :key="i"
      class="w-[320px] h-[400px] flex flex-col rounded-lg overflow-hidden mt-4">
      <div class="animate-pulse flex flex-col">
        <div class="rounded-lg bg-slate-300 w-[320px] h-[180px]"></div>
        <div class="h-10 bg-slate-300 rounded-xl mt-4"></div>
        <div class="h-6 bg-slate-300 rounded-xl  mt-4"></div>
      </div>
    </div>
  </template>
</template>

<script>

export default {
  name: 'CardNewsroomItem',

  props: {
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    skeleton: {
      type: Boolean,
      required: false,
      default: false,
    },
    canShare: {
      type: Boolean,
      required: false,
      default: false,
    },
    shareData: {
      type: Function,
      required: false,
      default: () => {},
    },
    remove: {
      type: Function,
      required: false
    },
    publish: {
      type: Function,
      required: false
    },
    unPublish: {
      type: Function,
      required: false
    },
  },

  data() {
    return {
      options: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
    }
  },
  computed: {
    redirectURL() {
      return `/newsroom/${this.data.slug}`;
    },
    createDate() {
      const date = new Date(this.data.createDate);
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      return date.toLocaleDateString('en-GB', options);
    },
    cover() {
      return this.data.media ? this.data.media : '/cover.svg';
    },
  },

  methods: {
    previewCard() {
      return this.$router.push(this.redirectURL);
    },
    editData() {
      return this.$router.push({ path: `/configurator/${this.data.uuid}`})
    },
  },
};
</script>
