<template>
  <div
    v-show="isConfigurator"
    class="fixed inset-0 bg-[#0000004D] bg-opacity-75 transition-opacity z-30"
    aria-hidden="true">
  </div>
  <div
    :class="
      isConfigurator ?
      'relative z-50 max-w-[870px] mx-auto w-full h-fit'
      :
      'flex flex-col max-w-[870px] bg-light rounded-md'
    "> 
    <div
      v-show="isConfigurator"
      class="w-full items-center justify-center absolute">
      <div
        class="w-[36px] h-[36px] bg-white rounded-full flex items-center justify-center absolute right-[-0px] md:right-[-42px] top-[-48px] md:top-[-32px]"
        aria-hidden="true"
        @click="close">
        <xdem-icon
          icon="bg-xdem-exit"
          size="32"/>
      </div> 
    </div> 
    <div class="flex flex-col bg-light rounded-2xl px-4 sm:px-8 md:px-12 2xl:px-16 pt-4 pb-6">
      <div class="flex flex-col sm:flex-row items-center justify-between py-2 sm:py-4 border-b border-gray">
        <div class="flex flex-row items-center justify-center">
          <img
            :src="userData.avatar"
            :title="userData.name"
            class="w-[32px] h-[32px] rounded-full bg-contain bg-center bg-no-repeat">
          <div class="flex flex-col ml-2">
            <div class="flex flex-row items-center justify-start">
              <p class="text-xs font-bold text-slate-700 line-clamp-1">
                {{ userData.name }}
              </p>
              <xdem-icon
                v-if="userData.isVerified"
                icon="bg-xdem-verified"
                size="12"
                custom="ml-1"/>
            </div>
            <div class="flex flex-row items-center justify-center pt-0.5">
              <p class="text-xs font-normal text-primary-dark">
                {{ isConfigurator ? `Preview your article` : data.isPublish ? `Published at ${publishedDate}` : `Last updated at ${updatedDate}` }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="!isConfigurator"
          class="flex flex-row py-2 sm:py-0">
          <div
            class="flex items-center justify-center mr-4 cursor-pointer"
            @click="edit">
            <xdem-icon
              icon="bg-xdem-edit"
              size="18"/>
            <p class="text-base font-medium text-primary ml-1">
              Edit
            </p>
          </div>
          <!-- <div
            v-if="data.isPublish"
            class="flex items-center justify-center mr-4 cursor-pointer"
            @click="shareData">
            <xdem-icon
              icon="bg-xdem-share"
              size="18"/>
            <p class="text-base font-medium text-primary ml-1">
              Share
            </p>
          </div> -->
          <!-- <div
            v-if="!data.isPublish"
            class="flex items-center justify-center cursor-pointer"
            @click="remove">
            <xdem-icon
              icon="bg-xdem-delete"
              size="18"/>
            <p class="text-base font-medium text-primary ml-1">
              Delete
            </p>
          </div> -->
          <button
            v-if="!data.isPublish"
            class="bg-primary hover:bg-primary--600 active:bg-primary--700 rounded ml-1 py-2 px-6  ml-4"
            @click="publish">
            <p class="text-base text-white font-semibold">
              Publish
            </p>
          </button>
        </div>
      </div>
      <div class="flex flex-col overflow-hidden">
        <div
          v-show="!isConfigurator"
          class="flex items-center pt-4">
          <xdem-icon
            icon="bg-xdem-eye-open"
            size="20"/>
          <p class="text-base font-medium text-primary-dark ml-1">
            {{ data.view_count }} Views
          </p>
        </div>
        <div
          v-html="data.title"
          class="text-[32px] font-semibold py-6"/>
        <div
          v-if="data.media"
          class="min-w-full h-fit relative flex items-center justify-center">
          <video
            v-if="['video/mp4', 'video/ogg', 'video/webm'].includes(data.media_type)"
            style="background: black;"
            allow="accelerometer; clipboard-write; encrypted-media;"
            allowfullscreen
            controls
            class="max-h-[400px] min-h-[150px] w-auto rounded-lg">
            <source
              :src="mediaUrl(data.media)"
              :type="data.media_type">
          </video>
          <img
            v-else
            :src="mediaUrl(data.media)"
            :alt="data.media_desc"
            class="max-h-[400px] min-h-[150px] w-auto rounded-lg">
        </div>
        <div
          v-html="data.media_desc"
          class="text-[12px] font-normal text-center text-[#4F4F4F] break-words mt-3" />
        <div
          v-html="data.content"
          class="text-justify italic break-words mt-6" />
        <div
          v-for="(item, idx) in data.sections"
          :key="idx"
          class="pt-6">
          <div
            v-html="item.title"
            class="text-[20px] font-semibold text-[#333333] break-words" />
          <div
            v-html="item.content"
            class="text-justify pt-4 break-words" />
          <div
            v-html="item.url"
            class="text-[16px] font-normal break-words my-4"
            :class="[isValidUrl(item.url) ? 'text-primary' : 'text-[#4F4F4F]']" />
          <div
            v-if="item.media"
            class="min-w-full h-fit relative flex items-center justify-center">
            <video
              v-if="['video/mp4', 'video/ogg', 'video/webm'].includes(item.media_type)"
              style="background: black;"
              allow="accelerometer; clipboard-write; encrypted-media;"
              allowfullscreen
              controls
              class="max-h-[400px] min-h-[150px] w-auto rounded-lg">
              <source
                :src="mediaUrl(item.media)"
                :type="item.media_type">
            </video>
            <img
              v-else
              :src="mediaUrl(item.media)"
              :alt="item.media_desc"
              class="max-h-[400px] min-h-[150px] w-auto rounded-lg">
          </div>
          <div
            v-html="item.media_desc"
            class="text-[12px] font-normal text-center text-[#4F4F4F] break-words mt-3" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PreviewNewsroom',
  props: {
    isConfigurator: {
      type: Boolean,
      required: false,
      default: false,
    },
    userData: {
      type: Object,
      required: false,
      default: () => {},
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    baseUrl: {
      type: String,
      required: false,
      default: '',
    },
    edit: {
      type: Function,
      required: false
    },
    remove: {
      type: Function,
      required: false
    },
    save: {
      type: Function,
      required: false
    },
    publish: {
      type: Function,
      required: false
    },
    close: {
      type: Function,
      required: false
    },
    shareData: {
      type: Function,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      options: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
    }
  },
  computed: {
    publishedDate() {
      const date = new Date(this.data.updatedDate)
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      return date.toLocaleDateString('en-GB', options);
    },
    updatedDate() {
      const date = new Date(this.data.updatedDate)
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      return date.toLocaleDateString('en-GB', options);
    },
  },

  methods: {
    isValidUrl(url) {
      try {
        const urlObject = new URL(url);
        return ['http:', 'https:'].includes(urlObject.protocol);
      } catch (_) {
        return false;
      }
    },
    mediaUrl(media) {
      return media.split("/").length === 1 ? this.baseUrl+media : media;
    },
  },
};
</script>
